import black_forest_cake from "../img/cakes/black-forest-cake-7.webp";
import lemon_poppy_cake from "../img/cakes/lemon-poppy-seed-cake-4.webp";
import strawberry_shortcake_cake from "../img/cakes/strawberry-shortcake-cake-2.webp";
import bakewell_cake from "../img/cakes/bakewell-cake-2.webp";
import mocha_cake_cake from "../img/cakes/mocha_cake_black-1.webp";
import white_chocolate_candy_cake from "../img/cakes/white-chocolate-candy-cane-cake-4.webp";

export const cakesData = [
  {
    id: 1,
    title: "Black Forest Cake",
    readyInMinutes: 45,
    image: black_forest_cake,
    servings: 1,
    summary:
      "This Black Forest Cake combines rich chocolate cake layers with fresh cherries, cherry liqueur, and a simple whipped cream frosting.Black Forest Cake was a staple when I was growing up (much to my dismay…read on).You know, the grocery store kind with the nuclear red maraschino cherries? My mom loooooved that cake and always got it for her birthday, and often at other times throughout the year as well. This was epically disappointing to me.Why on earth would you ruin a perfectly delicious cake with those sickly sweet, sticky, artificial tasting cherries? Whyyyyy? Needless to say, I ate around them, but they totally tainted the cake and frosting. Sad times.Now that I think about it, it’s probably been a good 20-25 years since I’ve had that cake. I’ve talked about my disdain for maraschino cherries before, and that feeling still holds true to this day. I have caved and used them as a garnish because I will admit, they look pretty and just go with certain desserts, but they get plucked right off before eating the cake.Thing is, I actually love cherries. Real cherries. Fresh cherries. They are one of my favourite summer seasonal fruits. So, I set off to re-create the decades-old Black Forest Cake my way.I promise you, this is one of the easiest cakes you will make.It looks fancier than it is, and the chocolate bark was actually dead easy to make. The trickiest part of this cake is making sure you don’t have any lingering cherry pits in your cherries, which totally happened due to a less-than-stellar cherry pitter that broke after about 10 cherries.Needless to say, it got returned.So, super easy chocolate cake, a simple whipped cream for frosting, and fresh cherries. That’s really all you need.In order to keep it traditional, I also made a cherry syrup (using kirsch – cherry liqueur) to brush onto the cake layers.You can leave this out if you’d like to keep it kid-friendly or use a non-alcoholic cherry syrup.",
    extendedIngredients: [
      {
        id: 1,
        original: "2 cup all-purpose flour",
      },
      {
        id: 2,
        original: "2 cups granulated sugar",
      },
      {
        id: 3,
        original: "3/4 cup Dutch-processed cocoa powder sifted",
      },
      {
        id: 4,
        original: "2 tsp baking soda",
      },
      {
        id: 5,
        original: "1 tsp baking powder",
      },
      {
        id: 6,
        original: "1 tsp salt",
      },
      {
        id: 7,
        original: "1/2 cup vegetable oil",
      },
      {
        id: 8,
        original: "1 cup buttermilk room temperature",
      },
      {
        id: 9,
        original: "1 cup hot water or hot coffee",
      },
      {
        id: 10,
        original: "2 large eggs",
      },
      {
        id: 11,
        original: "2 tsp vanilla extract",
      },
      {
        id: 12,
        original: "1/2 cup granulated sugar",
      },
      {
        id: 13,
        original: "1/2 cup water",
      },
      {
        id: 14,
        original: "1/4 cup cherry liqueur",
      },
      {
        id: 15,
        original: "3 cup whipping cream cold",
      },
      {
        id: 16,
        original: "1/4 cup powdered sugar sifted",
      },
      {
        id: 17,
        original: "250 g good quality dark chocolate chopped",
      },
      {
        id: 18,
        original: "2 1/2 cups cherries pitted and cut in half",
      },
      {
        id: 19,
        original: "1 bar dark chocolate for shavings (optional)",
      },
      {
        id: 20,
        original: "cherries",
      },
    ],
    analyzedInstructions: [
      {
        name: "Chocolate Cake",
        steps: [
          {
            number: 1,
            step: 'Preheat oven to 350F, grease two 8" round baking pans and dust with cocoa powder. Line bottoms with parchment.',
          },
          {
            number: 2,
            step: "Place all dry ingredients into the bowl of a stand mixer fitted with a paddle attachment. Stir to combine.",
          },
          {
            number: 3,
            step: "In a medium bowl whisk all wet ingredients (pour hot water in slowly as not to cook the eggs).",
          },
          {
            number: 4,
            step: "Add wet ingredients to dry and mix on medium for 2-3 mins. Batter will be very thin.",
          },
          {
            number: 5,
            step: "Pour evenly into prepared pans. I used a kitchen scale to ensure the batter is evenly distributed.",
          },
          {
            number: 6,
            step: "Bake for 45 mins or until a cake tester comes out mostly clean.",
          },
          {
            number: 7,
            step: "Cool 10 minutes in the pans then turn out onto a wire rack to cool completely.",
          },
        ],
      },
      {
        name: "Cherry Liqueur Syrup",
        steps: [
          {
            number: 1,
            step: "Place sugar and water into a small pot. Stir and bring to a boil. Simmer for 1 min then remove from heat. Stir in cherry liqueur and allow to cool completely.",
          },
        ],
      },
      {
        name: "Whipped Cream Frosting",
        steps: [
          {
            number: 1,
            step: "Whip cream and powdered sugar until stiff peaks. Ideally in a cold bowl with a cold whisk.",
          },
        ],
      },
      {
        name: "Chocolate Bark",
        steps: [
          {
            number: 1,
            step: "Melt chocolate over a double boiler or in 20 second bursts in the microwave.",
          },
          {
            number: 2,
            step: "Using a large offset spatula, spread melted chocolate in a thin layer on a large sheet of parchment.",
          },
          {
            number: 3,
            step: "Roll up from the short side of the parchment. Place on a baking sheet and refrigerate or freeze until firm.",
          },
          {
            number: 4,
            step: "Unroll to create chocolate bark.",
          },
        ],
      },
      {
        name: "Assembly",
        steps: [
          {
            number: 1,
            step: "Cut each cake layer in half horizontally.",
          },
          {
            number: 2,
            step: "Place one layer of cake on a cake stand or serving plate. Brush generously with cherry syrup.",
          },
          {
            number: 3,
            step: "Top with approximately 1 cup whipped cream and spread evenly. Top with approximately 1 cup of cherries and gently press them into the whipped cream. Repeat with remaining layers and frost the outside of the cake.",
          },
          {
            number: 4,
            step: "Decorate with chocolate bark, chocolate shavings, rosettes, and cherries if desired.",
          },
        ],
      },
    ],
  },
  {
    id: 2,
    title: "Lemon Poppy Seed Cake",
    readyInMinutes: 35,
    image: lemon_poppy_cake,
    servings: 1,
    summary:
      "Tender lemon poppy seed cake layers drizzled with lemon syrup and topped with a tangy cream cheese frosting.I’ve said it before, I am a HUGE fan of lemon desserts. More so than any other flavor. If there is a lemon dessert on the menu you can bet I’m ordering that over something chocolate (unless it’s panna cotta, no thank you jello-like texture). There is just something about the sweet and tangy flavor that has me coming back for more. Today, I’m bringing you the classic combo of lemon and poppy seeds in layer cake form. Oh and it’s paired with my delicious new cream cheese frosting recipe for good measure.",
    extendedIngredients: [
      {
        id: 1,
        original: "1/4 cup granulated sugar",
      },
      {
        id: 2,
        original: "1/4 cup lemon juice fresh squeezed, from one large lemon",
      },
      {
        id: 3,
        original: "1 cup unsalted butter room temperature",
      },
      {
        id: 4,
        original: "4 cups powdered sugar sifted",
      },
      {
        id: 5,
        original: "2 teaspoons vanilla extract",
      },
      {
        id: 6,
        original:
          "1 cup cream cheese (block style, full fat) 8oz, chilled, cubed",
      },
      {
        id: 7,
        original: "cornstarch or meringue powder optional, as needed",
      },
      {
        id: 8,
        original: "pinch salt optional, to cut some sweetness",
      },
      {
        id: 9,
        original: "lemon slices",
      },
      {
        id: 10,
        original: "edible flowers I used pansies",
      },
      {
        id: 11,
        original: "poppy seeds",
      },
    ],
    analyzedInstructions: [
      {
        name: "Lemon Poppy Seed Cake",
        steps: [
          {
            number: 1,
            step: 'Preheat oven to 350F. Grease and flour three 6" cake rounds and line with parchment.',
          },
          {
            number: 2,
            step: "In a medium bowl, whisk flour, poppy seeds, baking powder, and salt until well combined. Set aside.",
          },
          {
            number: 3,
            step: "Using a stand mixer fitted with a paddle attachment, cream butter and lemon zest until smooth. Add sugar and beat on med-high until pale and fluffy (approx 3mins).",
          },
          {
            number: 4,
            step: "Reduce speed and add eggs one at a time fully incorporating after each addition. Add vanilla.",
          },
          {
            number: 5,
            step: "Alternate adding flour mixture with milk & lemon juice, beginning and ending with flour (3 additions of flour and 2 of milk & lemon juice). Fully incorporating after each addition.",
          },
          {
            number: 6,
            step: "Spread batter evenly between the prepared pans and smooth the tops.",
          },
          {
            number: 7,
            step: "Bake for about 30-35mins or until a toothpick inserted into the center comes out mostly clean.",
          },
          {
            number: 8,
            step: "Place cakes on wire rack to cool for 10mins then turn out onto wire rack to cool completely.",
          },
        ],
      },
      {
        name: "Lemon Simple Syrup",
        steps: [
          {
            number: 1,
            step: "Place lemon juice and sugar into a small saucepan, stir to combine. Cook over medium heat, stirring often, until simmering and all sugar has dissolved. Cool completely before using on cake.",
          },
        ],
      },
      {
        name: "Cream Cheese Frosting",
        steps: [
          {
            number: 1,
            step: "Using a stand mixer or hand mixer beat butter until pale and creamy.",
          },
          {
            number: 2,
            step: "Reduce speed to low. Add powdered sugar 1 cup at a time and mix on low until well blended. Increase speed to medium and beat for 3 minutes. Add vanilla and continue to beat on medium for 1 minute.",
          },
          {
            number: 3,
            step: "Slowly add chilled cream cheese one cube at a time (about 1 tablespoon at a time). Beat well until blended and ensure no lumps of cream cheese remain.",
          },
          {
            number: 4,
            step: "If needed, add cornstarch or meringue powder 1 tablespoon at a time to stiffen the frosting (I didn't but this is an option). Add a pinch of salt to cut some sweetness if desired.",
          },
          {
            number: 5,
            step: "Run mixer on low for a couple of minutes to remove any air bubbles.",
          },
        ],
      },
      {
        name: "Assembly",
        steps: [
          {
            number: 1,
            step: "Trim the crust off the top of each cake layer and brush each with the lemon simple syrup. About 1-2 Tbsp per layer.",
          },
          {
            number: 2,
            step: "Place one layer of cake on a cake stand or serving plate. Top with 2/3 cup of frosting, spread evenly. Repeat with the remaining layers and apply a thin coat of frosting all over the cake. Chill for 20mins.",
          },
          {
            number: 3,
            step: "Use the remaining frosting to frost the cake in a rustic manner.",
          },
          {
            number: 4,
            step: "Decorate with fresh, edible flowers and lemon slices if desired.",
          },
        ],
      },
    ],
  },
  {
    id: 3,
    title: "Strawberry Shortcake Cake with Mascarpone Cream",
    readyInMinutes: 30,
    image: strawberry_shortcake_cake,
    servings: 1,
    summary:
      "This Strawberry Shortcake Cake gives a new twist to an old classic. Vanilla cake layers, fresh strawberries, and mascarpone cream!How do you know if you’re allergic to something?I feel like I might be mildly allergic to strawberries, but not in the typical way. Occasionally (but not always) when I eat some I get a bit of a weird feeling in my throat, kind of a scratchiness, or tightness, or something. But when I was making this Strawberry Shortcake Cake it was different — I hadn’t eaten any of the strawberries yet. I was quartering about 4 cups of them for the filling and I started to find it hard to breathe. Not like call an ambulance hard to breathe, but I felt like my throat was closing up a bit, just from the SMELL. Is that possible? They were crazy fragrant. Some borderline overripe and very potent. It took about an hour for the feeling to subside once I was away from them. Maybe it’s all psychosomatic or maybe I’m just allergic to strong smells of any kind. *Shrug*. I’m still alive today but let it be known I may have risked my life making this Strawberry Shortcake Cake for you!raditionally, strawberry shortcake is a dessert consisting of a shortcrust biscuit that’s cut in half and sandwiched with whipped cream and macerated strawberries.My interpretation of the traditional strawberry shortcake consists of buttermilk vanilla cake layers, fresh macerated strawberries, strawberry syrup, and mascarpone whipped cream. The latter which is really the star of the show here.",
    extendedIngredients: [
      {
        id: 1,
        original: "3 cups all-purpose flour",
      },
      {
        id: 2,
        original: "1 Tbsp baking powder",
      },
      {
        id: 3,
        original: "1 tsp salt",
      },
      {
        id: 4,
        original: "1 cup unsalted butter room temperature",
      },
      {
        id: 5,
        original: "2 cups granulated sugar",
      },
      {
        id: 6,
        original: "4 large eggs room temperature",
      },
      {
        id: 7,
        original: "2 tsp vanilla extract",
      },
      {
        id: 8,
        original: "1 1/3 cup buttermilk room temperature",
      },
      {
        id: 9,
        original: "4 cups hulled & quartered strawberries 1 pint, 675g",
      },
      {
        id: 10,
        original: "1/4 cup granulated sugar",
      },
      {
        id: 11,
        original: "8 oz mascarpone slightly softened",
      },
      {
        id: 12,
        original: "1 cup whipping cream cold",
      },
      {
        id: 13,
        original: "1/2 cup powdered sugar sifted",
      },
      {
        id: 14,
        original: "1 tsp vanilla extract",
      },
    ],
    analyzedInstructions: [
      {
        name: "Vanilla Cake",
        steps: [
          {
            number: 1,
            step: "Preheat oven to 350F. Grease and flour three 8″ cake rounds and line with parchment.",
          },
          {
            number: 2,
            step: "In a medium bowl, whisk flour, baking powder, and salt until well combined. Set aside.",
          },
          {
            number: 3,
            step: "Using a stand mixer fitted with a paddle attachment, cream butter and sugar on med-high until pale and fluffy (approx 3mins).",
          },
          {
            number: 4,
            step: "Reduce speed and add eggs one at a time fully incorporating after each addition. Add vanilla.",
          },
          {
            number: 5,
            step: "Alternate adding flour mixture with buttermilk, beginning and ending with flour (3 additions of flour and 2 of milk). Fully incorporating after each addition.",
          },
          {
            number: 6,
            step: "Spread batter evenly between the prepared pans and smooth the tops.",
          },
          {
            number: 7,
            step: "Bake for about 30 mins or until a toothpick inserted into the center comes out mostly clean.",
          },
          {
            number: 8,
            step: "Place cakes on wire rack to cool for 10mins then turn out onto wire rack to cool completely.",
          },
        ],
      },
      {
        name: "Strawberries",
        steps: [
          {
            number: 1,
            step: "Place quartered strawberries and sugar into a medium bowl. Stir to combine. Place in fridge and let sit for 15mins (up to overnight).",
          },
          {
            number: 2,
            step: "Drain strawberries before using in cake. Reserve syrup to drizzle on cake layers during assembly if desired.",
          },
        ],
      },
      {
        name: "Mascarpone Whipped Cream",
        steps: [
          {
            number: 1,
            step: "Place mixer bowl and whisk into the fridge or freezer to chill.",
          },
          {
            number: 2,
            step: "Pour cold cream into the bowl and whip on high until stiff peaks form. Set aside.",
          },
          {
            number: 3,
            step: "In a separate bowl, beat mascarpone until smooth. Add sugar and beat until combined*",
          },
          {
            number: 4,
            step: "Combine whipped cream and mascarpone mixture until smooth and stiff.*",
          },
        ],
      },
      {
        name: "Assembly",
        steps: [
          {
            number: 1,
            step: "Divide mascarpone cream into 3 equal portions.",
          },
          {
            number: 2,
            step: "Place one layer of cake on a cake stand or serving plate. Drizzle with reserved strawberry syrup if desired.",
          },
          {
            number: 3,
            step: "Roughly spread 1/3 of the mascarpone cream over the top of the layer. I used the back of a large spoon to do this.",
          },
          {
            number: 4,
            step: "Place 1 1/2 cups of quartered strawberries on top. Repeat with remaining layers leaving the top layer just with the mascarpone cream.**",
          },
          {
            number: 5,
            step: "Refrigerate until serving. Serve same day.",
          },
        ],
      },
    ],
  },
  {
    id: 4,
    title: "Bakewell Cake (Raspberry Almond Cake)",
    readyInMinutes: 35,
    image: bakewell_cake,
    servings: 1,
    summary:
      "This Raspberry Almond Cake is my version of the classic Bakewell Tart in cake form. Almond cake layers, almond & raspberry buttercream, with fresh raspberries and almond slices. The perfect Bakewell Cake!This didn’t start out as a Bakewell Cake. I wanted to make a simple Raspberry Almond Cake to pair my favorite Almond Cake Recipe with my favorite berry. As I was researching classic raspberry almond flavor pairings I came across the Bakewell Tart. The Bakewell Tart consists of a pastry base filled with jam (often raspberry), frangipane (sweet almond paste), and flaked almonds. It’s associated with the town of Bakewell in Derbyshire, England though there is some contention around where it originated. I think I first heard of it during an episode of The Great British Bake Off.Since I consider myself an honorary citizen of the UK (until I can be an actual one – dare to dream), this Bakewell Cake needed to happen.",
    extendedIngredients: [
      {
        id: 1,
        original: "1 1/2 cups all-purpose flour",
      },
      {
        id: 2,
        original: "3/4 cup almond flour",
      },
      {
        id: 3,
        original: "2 tsp baking powder",
      },
      {
        id: 4,
        original: "3/4 tsp salt",
      },
      {
        id: 5,
        original: "3/4 cup unsalted butter room temperature",
      },
      {
        id: 6,
        original: "1 1/2 cup granulated sugar",
      },
      {
        id: 7,
        original: "3 large eggs room temperature",
      },
      {
        id: 8,
        original: "1 1/2 tsp vanilla extract",
      },
      {
        id: 9,
        original: "3/4 cup milk room temperature",
      },
      {
        id: 10,
        original:
          "1/4 cup Amaretto liqueur see Notes section for alcohol-free option",
      },
      {
        id: 11,
        original: "6 large egg whites",
      },
      {
        id: 12,
        original: "2 cup granulated sugar",
      },
      {
        id: 13,
        original: "2 cups unsalted butter room temperature",
      },
      {
        id: 14,
        original: "4 Tbsp freeze-dried raspberry powder",
      },
      {
        id: 15,
        original: "4-6 drops almond extract",
      },
      {
        id: 16,
        original:
          "12 oz fresh raspberries roughly chopped, plus more for topping, 340g",
      },
      {
        id: 17,
        original:
          "4-6 Tbsp raspberry syrup (or coulis) (optional) store-bought or homemade",
      },
      {
        id: 18,
        original: "sliced almonds",
      },
    ],
    analyzedInstructions: [
      {
        name: "Almond Cake",
        steps: [
          {
            number: 1,
            step: "Preheat oven to 350F. Grease and flour three 6″ cake rounds and line with parchment.",
          },
          {
            number: 2,
            step: "In a medium bowl, whisk flour, almond flour, baking powder, and salt until well combined. Set aside.",
          },
          {
            number: 3,
            step: "Combine milk and Amaretto. Set aside.",
          },
          {
            number: 4,
            step: "Using a stand mixer fitted with a paddle attachment, cream butter and sugar on med-high until pale and fluffy (3mins). Reduce speed and add eggs one at a time fully incorporating after each addition. Add vanilla.",
          },
          {
            number: 5,
            step: "Alternate adding flour mixture and milk mixture, beginning and ending with flour (3 additions of flour and 2 of milk). Fully incorporating after each addition.",
          },
          {
            number: 6,
            step: "Bake for approx. 35mins or until a toothpick inserted into the center comes out mostly clean.",
          },
          {
            number: 7,
            step: "Place cakes on wire rack to cool for 10mins then turn out onto wire rack. Allow cakes to cool completely.",
          },
        ],
      },
      {
        name: "Swiss Meringue Buttercream",
        steps: [
          {
            number: 1,
            step: "Place egg whites and sugar into the bowl of a stand mixer, whisk until combined.*",
          },
          {
            number: 2,
            step: 'Place bowl over a pot with 1-2" of simmering water and stir constantly with a whisk until the mixture is hot and no longer grainy to the touch or reads 160F on a candy thermometer (approx. 3mins)',
          },
          {
            number: 3,
            step: "Place bowl on your stand mixer and whisk on med-high until the meringue is stiff and cooled (the bowl is no longer warm to the touch (approx. 5-10mins)).",
          },
          {
            number: 4,
            step: "Switch to paddle attachment. Slowly add cubed butter and mix until smooth.**",
          },
          {
            number: 5,
            step: "Remove 3 cups of buttercream. Add 1/4 tsp almond extract to those 3 cups and whip well (I did this with a spatula). Add 4 Tbsp freeze-dried raspberry powder to remaining buttercream and beat well to combine.",
          },
        ],
      },
      {
        name: "Assembly",
        steps: [
          {
            number: 1,
            step: "Trim tops of each cake layer just slightly (to help absorb the syrup). Drizzle each with 1-2 Tbsp raspberry syrup (or coulis). Spread with an offset spatula to cover.",
          },
          {
            number: 2,
            step: "Place one layer of cake on a cake stand or serving plate. Top with 1/2 cup of almond buttercream and spread evenly. Gently press about 6 Tbsp fresh raspberries into the top. Repeat with next layer and place final layer on top. Frost and smooth the outside with a thin crumb coat of the almond buttercream. Chill for 20mins.",
          },
          {
            number: 3,
            step: "Frost the top and sides of the cake with the raspberry buttercream. Smooth with a bench scraper. Pipe dollops on top if desired with the remaining almond buttercream using an Ateco 867 or Wilton 6B tip. Place a raspberry onto each dollop.",
          },
          {
            number: 4,
            step: "Press toasted almonds into the bottom and fill in the middle of the top of the cake. Sprinkle chopped freeze-dried raspberries on top if desired.",
          },
        ],
      },
    ],
  },
  {
    id: 5,
    title: "Chocolate Mocha Cake",
    readyInMinutes: 35,
    image: mocha_cake_cake,
    servings: 1,
    summary:
      "This Chocolate Mocha Cake is the ultimate indulgence. A rich dark chocolate cake with a silky mocha Swiss meringue buttercream.This cake right here? It’s amazing. I mean, it knocked the Momofuku Birthday Cake right out of the #1 spot, and that was a damn good cake.This Chocolate Mocha Cake is seriously. SO. GOOD. Rich dark chocolate cake and a silky Mocha Swiss Meringue buttercream, topped with a dreamy chocolate ganache. Does it get any better? I don’t think so.If you’ve been following Liv for Cake for a while, you know this is one of my oldest (and one of my favorite) recipes. If you haven’t tried it, you NEED to. You will not regret it.The frosting is my favorite thing ever — you’ll be eating that with a spoon and thanking me later.",
    extendedIngredients: [
      {
        id: 1,
        original: "1 1/2 cups all-purpose flour",
      },
      {
        id: 2,
        original: "1 1/2 cups granulated sugar",
      },
      {
        id: 3,
        original: "3/4 cup Dutch-processed cocoa powder sifted",
      },
      {
        id: 4,
        original: "1 1/2 tsp baking soda",
      },
      {
        id: 5,
        original: "1 tsp baking powder",
      },
      {
        id: 6,
        original: "1 tsp salt",
      },
      {
        id: 7,
        original: "1/4 cup vegetable oil",
      },
      {
        id: 8,
        original: "3/4 cup buttermilk",
      },
      {
        id: 9,
        original: "3/4 cup strong brewed coffee hot",
      },
      {
        id: 10,
        original: "2 large eggs",
      },
      {
        id: 11,
        original: "2 tsp vanilla extract",
      },
      {
        id: 12,
        original: "3 large egg whites",
      },
      {
        id: 13,
        original: "1 cup granulated sugar",
      },
      {
        id: 14,
        original: "1 1/2 cups unsalted butter room temperature, cubed",
      },
      {
        id: 15,
        original: "1 tsp vanilla extract",
      },
      {
        id: 16,
        original:
          "1 Tbsp instant espresso powder dissolved in 1 tsp hot water, cooled",
      },
      {
        id: 17,
        original: "3 oz dark chocolate chopped, melted, and cooled",
      },
      {
        id: 18,
        original: "3 oz dark chocolate",
      },
      {
        id: 19,
        original: "3 oz heavy whipping cream",
      },
    ],
    analyzedInstructions: [
      {
        name: "Chocolate Cake",
        steps: [
          {
            number: 1,
            step: "Preheat oven to 350F, grease three 6″ round baking pans and dust with cocoa powder. Line bottoms with parchment.",
          },
          {
            number: 2,
            step: "Place all dry ingredients into the bowl of a stand mixer fitted with a paddle attachment. Stir to combine.",
          },
          {
            number: 3,
            step: "In a medium bowl whisk all wet ingredients (pour coffee in slowly as not to cook the eggs if very hot).",
          },
          {
            number: 4,
            step: "Add wet ingredients to dry and mix on medium for 2-3 mins. Batter will be very thin.",
          },
          {
            number: 5,
            step: "Pour evenly into prepared pans. I used a kitchen scale to ensure the batter is evenly distributed.",
          },
          {
            number: 6,
            step: "Bake until a cake tester comes out mostly clean. A total of 30-35mins.",
          },
        ],
      },
      {
        name: "Mocha Buttercream",
        steps: [
          {
            number: 1,
            step: "Place egg whites and sugar into the bowl of a stand mixer, whisk until combined.*",
          },
          {
            number: 2,
            step: 'Place bowl over a pot with 1-2" of simmering water and stir constantly with a whisk until the mixture is hot and no longer grainy to the touch or reads 160F on a candy thermometer (about 3mins).',
          },
          {
            number: 3,
            step: "Place bowl on your stand mixer and whisk on med-high until the meringue is stiff and cooled (the bowl is no longer warm to the touch (approx. 5-10mins))",
          },
          {
            number: 4,
            step: "Switch to paddle attachment (optional). Slowly add cubed butter and mix until smooth.",
          },
          {
            number: 5,
            step: "Add vanilla, melted chocolate, and espresso powder. Whip until smooth.**",
          },
        ],
      },
      {
        name: "Ganache Drip",
        steps: [
          {
            number: 1,
            step: "Finely chop chocolate and place into a bowl. Bring cream just barely to a simmer and pour over chopped chocolate. Cover with plastic wrap and let stand 2 mins. Stir gently with a spatula until combined and smooth. Allow to cool and thicken slightly before using on cake.***",
          },
        ],
      },
      {
        name: "Assembly",
        steps: [
          {
            number: 1,
            step: "Place one layer of cake on a cake stand or serving plate. Top with approximately 2/3 cup of frosting and spread evenly. Repeat with remaining layers and apply a thin coat of frosting all over the cake. Chill for 20mins.",
          },
          {
            number: 2,
            step: "Frost and smooth the sides. Chill for 20mins.",
          },
          {
            number: 3,
            step: "Using a small spoon, place dollops of ganache around the top edges of the cooled cake, allowing some to drip down. Fill in the top of the cake with more ganache and spread evenly with an offset spatula.",
          },
        ],
      },
    ],
  },
  {
    id: 6,
    title: "White Chocolate Candy Cane Cake",
    readyInMinutes: 50,
    image: white_chocolate_candy_cake,
    servings: 1,
    summary:
      "This White Chocolate Candy Cane Cake is the perfect addition to your holiday celebrations!I don’t know about you, but the holidays have started to infiltrate our place.It actually started two weeks ago, when we got back from celebrating my birthday in Walt Disney World (which was amazing). As soon as Nov 1st hit, it was out with everything Fall/Halloween/Pumpkin and in with festive holiday decor. Well, the holiday decor that was accessible to me, anyhow.There’s still a whole bunch stashed away in the depths of our storage room. I could try and fish it out myself, but knowing my clumsy and accident-prone nature, I’m sure to destroy something. It’s just safer if I wait for Ryan to help.Now that I think about it, maybe I’ll wrangle him into some of that today. Too soon??I know it’s too soon for some, but for the past couple of years, I feel like I’ve missed out a bit on the holiday spirit. This is the busiest time of year for a food/dessert blogger, so I’m usually knee-deep in baking, writing, answering your questions, etc.So I’ve brought a bit of Christmas out a little early so that I can enjoy it more. It is my favorite time of year, after all!I’ve actually managed to get a head start on Christmas shopping too, which is unheard of, as I’m usually scrambling a week into December!Naturally, with Christmas decor and Christmas shopping comes Christmas baking! There really is nothing better. I love the warm, comforting scents and flavors this time of year. So many delicious combinations of festive goodies and treats.Today, to kick off this wonderful season, I bring you this White Chocolate Candy Cane Cake!",
    extendedIngredients: [
      {
        id: 1,
        original: "5 oz white chocolate chopped (or chocolate chips)",
      },
      {
        id: 2,
        original: "1 1/2 cup milk room temperature",
      },
      {
        id: 3,
        original: "2 1/2 cups all-purpose flour",
      },
      {
        id: 4,
        original: "2 1/2 tsp baking powder",
      },
      {
        id: 5,
        original: "1/2 tsp salt",
      },
      {
        id: 6,
        original: "3/4 cup unsalted butter room temperature",
      },
      {
        id: 7,
        original: "1 1/2 cup granulated sugar",
      },
      {
        id: 8,
        original: "3 large eggs room temperature",
      },
      {
        id: 9,
        original: "1 tsp vanilla extract",
      },
      {
        id: 10,
        original: "1 tsp peppermint extract",
      },
      {
        id: 11,
        original: "6 large egg whites",
      },
      {
        id: 12,
        original: "1 1/2 cups granulated sugar",
      },
      {
        id: 13,
        original: "2 cups unsalted butter room temperature",
      },
      {
        id: 14,
        original: "6 oz white chocolate chopped, melted, cooled",
      },
      {
        id: 15,
        original: "1 tsp vanilla extract or peppermint if you prefer",
      },
      {
        id: 16,
        original: "red color gel",
      },
      {
        id: 17,
        original: "200 g bright white candy melts",
      },
      {
        id: 18,
        original: "75 g red candy melts",
      },
      {
        id: 19,
        original: "white chocolate curls",
      },
      {
        id: 20,
        original: "candy canes chopped/crushed",
      },
    ],
    analyzedInstructions: [
      {
        name: "White Chocolate Peppermint Cake",
        steps: [
          {
            number: 1,
            step: "Heat milk and chocolate until melted and combined, cool to room temperature.*",
          },
          {
            number: 2,
            step: "Preheat oven to 350F. Grease and flour two 8″ cake rounds and line with parchment.",
          },
          {
            number: 3,
            step: "In a medium bowl, whisk flour, baking powder, and salt until well combined. Set aside.",
          },
          {
            number: 4,
            step: "Using a stand mixer fitted with a paddle attachment, cream butter and sugar on med-high until pale and fluffy (approx 3mins). Reduce speed and add eggs one at a time fully incorporating after each addition. Add peppermint extract.",
          },
          {
            number: 5,
            step: "Alternate adding flour mixture and milk mixture, beginning and ending with flour (3 additions of flour and 2 of milk). Fully incorporating after each addition.",
          },
          {
            number: 6,
            step: "Bake for 45-50mins or until a toothpick inserted into the center comes out mostly clean.",
          },
          {
            number: 7,
            step: "Place cakes on wire rack to cool for 10mins then turn out onto wire rack and cool completely.",
          },
        ],
      },
      {
        name: "White Chocolate Swiss Meringue Buttercream",
        steps: [
          {
            number: 1,
            step: "Place egg whites and sugar into the bowl of a stand mixer, whisk until combined.**",
          },
          {
            number: 2,
            step: "Place bowl over a double boiler on the stove and whisk constantly until the mixture is hot and no longer grainy to the touch (approx. 3mins). Or registers 160F on a candy thermometer.",
          },
          {
            number: 3,
            step: "Place bowl on your stand mixer and whisk on med-high until the meringue is stiff and cooled (the bowl is no longer warm to the touch (approx. 5-10mins)).",
          },
          {
            number: 4,
            step: "Switch to paddle attachment. Slowly add cubed butter and mix until smooth.***",
          },
          {
            number: 5,
            step: "Add white chocolate, vanilla (or more peppermint extract) and whip until smooth.",
          },
        ],
      },
      {
        name: "Candy Cane Bark",
        steps: [
          {
            number: 1,
            step: "Melt red and white candy melts in bowls over simmering pots of water.",
          },
          {
            number: 2,
            step: "Using a large offset spatula, spread white candy melts in a thin, even layer on a large sheet of parchment.",
          },
          {
            number: 3,
            step: "Pour melted red candy melts into a piping bag. Snip off the tip to about 1/2″ in size. Pipe diagonal strips across the sheet of white. Spread using a small offset spatula to flatten.",
          },
          {
            number: 4,
            step: "Roll up from the short side of the parchment. Place on a baking sheet and refrigerate or freeze until firm (approx. 30mins in fridge).",
          },
          {
            number: 5,
            step: "Unroll to create chocolate bark.",
          },
        ],
      },
      {
        name: "Assembly",
        steps: [
          {
            number: 1,
            step: "Remove 1/3 of the frosting and color it red. You’ll need to use a fair amount of color gel to do this. Frosting will darken a bit over time.",
          },
          {
            number: 2,
            step: "Fill one piping bag, fitted with a large round tip with the red frosting, fill another with the white.",
          },
          {
            number: 3,
            step: "Place one layer of cake on a cake stand or serving plate. Alternate piping white and red circles starting from the outside edge working your way in.",
          },
          {
            number: 4,
            step: "Place second cake layer on top and repeat the same process.",
          },
          {
            number: 5,
            step: "Apply a thin coat of the white frosting on the outside of the cake. Smooth the sides and top. Leave a bit of the sides exposed for a naked look if desired. Chill for 20mins.",
          },
          {
            number: 6,
            step: "Place remaining red and white frosting in a separate piping bag fitted with a French piping tip. I used a spatula to spread the white on one side of the piping bag and the red on the other. Pipe rosettes on the top of the cake.",
          },
          {
            number: 7,
            step: "Fill the middle between the rosettes with chopped candy canes and white chocolate shavings if desired.",
          },
          {
            number: 8,
            step: "Place candy cane bark around the outside of the cake. Adhere with some extra buttercream if needed.",
          },
        ],
      },
    ],
  },
];
